import Grid from '@material-ui/core/Grid';
import { Formik, Field, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import { postRequest } from 'utils/api';
import style from './GetStarted.module.scss';

export default function GetStarted() {
  const navigate = useNavigate();

  async function handleGetStarted(values) {
    try {
      await postRequest('/web/subscriptions', { email: values.email }, { noAuth: true });
      navigate(`/auth/register?email=${values.email}`);
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <div className={style.getTouch}>
      <div className={style.blackBackground}>
        <Grid container spacing={3} className={style.container} style={{ width: '100%', margin: '0px' }}>
          <Grid item xs={12}>
            <div className={style.heading}>Empower Your Career Decisions</div>
            <Formik initialValues={{ email: '' }} onSubmit={handleGetStarted}>
              {({ isSubmitting }) => (
                <Form className={style.emailBox}>
                  <Field
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    style={{ maxWidth: '200px' }}
                  />
                  <button type="submit" disabled={isSubmitting}>Get Started</button>
                </Form>
              )}
            </Formik>
            <div className={style.explanation}>
              Join the CareerTest.ai community and discover your best-fit career paths
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
